.socials-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  padding-top: 50px;
  left: 0;
  bottom: 3rem;

  &::after,
  &::before {
    content: "";
    width: 80px;
    height: 1px;
    background-color: var(--theme-color-primary);
  }
}
