.links {
  display: flex;
  gap: 50px;
}

@media screen and (max-width: 700px) {
  .links {
    gap: 0;
    justify-content: space-evenly;
  }
}
