/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --color-bg: rgb(0, 0, 0);
  --color-bg-variant: #256d85;
  --color-primary: rgb(0, 0, 0);
  --color-white: rgb(0, 0, 0);
  --color-light: rgb(122, 122, 122); // rgba(255, 255, 255, 0.6);
  --color-bg-section: #f5f5f5;
  --button-color: #343ba5;
  --theme-color-principal: #343ca2;

  --transition: all 400ms ease;

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-ms: 90%;

  --font-size-sm: 14px;
  --font-size-md: 16px;
  --font-size-big: 24px;

  --theme-color-primary: #2ea2bac4;
  --theme-color-secondary: #232323;
  --theme-color-primary-bolt: #217c8f;
  --theme-color-title: #fff;
  --theme-color-subtitle: rgb(153, 152, 152);
  --theme-color-text: rgba(141, 138, 138, 0.896);
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  font-family: "Lato", sans-serif;
  background: var(--color-bg);
  color: var(--color-white);
  line-height: 1.7;
  padding: 0 20px;
  //background-image: url('./assets/bg-texture.png');
}

.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
}
h1 {
  font-size: 2.5rem;
}

section > h2,
section > h5 {
  text-align: center;
  color: var(--color-light);
}

section > h2 {
  color: var(--color-primary);
  margin-bottom: 3rem;
}

.text-light {
  color: var(--color-light);
}

// a {
//   color: var(--color-primary);
//   transition: var(--transition);
//   &:hover {
//     color: var(--color-light);
//   }
// }

// .btn {
//   width: max-content;
//   display: inline-block;
//   cursor: pointer;
//   border: 1px solid var(--color-primary);
//   transition: var(--transition);
//   color: var(--color-primary);
//   padding: 0.75rem 1.2rem;
//   border-radius: 0.4rem;

//   &:hover {
//     background-color: var(--color-light);
//     color: var(--color-bg);
//     border-color: transparent;
//   }
// }

// .btn-primary {
//   background: var(--color-primary);
//   color: var(--color-bg);
// }

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

.w-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}
.wf-section {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: transparent;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0.54)),
    to(rgba(0, 0, 0, 0.54))
  );
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.54),
    rgba(0, 0, 0, 0.54)
  );
  -o-object-fit: fill;
  object-fit: fill;
}

/* ---- media query --- */
@media screen and (max-width: 1024px) {
  header {
    height: 68vh;
  }
  .container {
    width: var(--container-width-md);
  }
  // section {
  //   margin-top: 6rem;
  // }
}

/* ---- media query --- */
@media screen and (max-width: 600px) {
  header {
    height: 100vh;
    .container {
      width: var(--container-width-sm);
    }
  }

  section > h2 {
    margin-bottom: 2rem;
  }
}

@media screen and (min-width: 1920px) {
  .brand.w--current {
    margin-left: 18vw;
  }
}
@media screen and (min-width: 1440px) {
  .brand.w--current {
    margin-left: 13vw;
  }
}
@media screen and (min-width: 1920px) {
  .brand {
    margin-left: 0;
  }
}
@media screen and (min-width: 1440px) {
  .brand {
    margin-left: 0;
  }
}
.brand.w--current {
  margin-left: 8vw;
}

// -------------------------

.w-conteiner {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}
@media screen and (min-width: 991px) {
  .w-conteiner {
    max-width: 728px;
  }
}
@media screen and (min-width: 479px) {
  .w-conteiner {
    max-width: none;
  }
}

p,
a,
h1,
h2,
h3,
h4,
h5 {
  color: var(--theme-color-title);
}

.section {
  margin-top: 50px;
  margin-bottom: 50px;
}

@keyframes loadtop {
  from {
    -moz-transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  to {
    -moz-transform: translateY(0%);
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

@keyframes loadbottom {
  from {
    -moz-transform: translateY(50%);
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
  }
  to {
    -moz-transform: translateY(0%);
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

@keyframes loadleft {
  from {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  to {
    -moz-transform: translateX(0%);
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@keyframes loadright {
  from {
    -moz-transform: translateX(10%);
    -webkit-transform: translateX(10%);
    transform: translateX(10%);
  }
  to {
    -moz-transform: translateX(0%);
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}
@keyframes loadrightcomplete {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(0%);
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}
