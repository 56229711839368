.companies {
  width: 100%;

  .companies__content {
    display: flex;
    margin-right: 0;
    margin-left: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: auto;
    align-items: center;

    :nth-child(2) {
      filter: invert(100%) brightness(250%);
    }

    h3 {
      padding: 0px 20px 0px 0px;
      color: var(--theme-color-primary);
    }
    .companies__list {
      filter: grayscale(100%);
      display: grid;
      height: auto;
      margin-top: 0;
      clear: none;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      justify-items: stretch;
      -webkit-box-align: stretch;
      -webkit-align-items: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
      -webkit-align-content: stretch;
      -ms-flex-line-pack: stretch;
      align-content: stretch;
      -ms-grid-row-align: center;
      align-self: center;
      -webkit-box-ordinal-group: 1;
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      grid-auto-flow: row;
      grid-auto-columns: 1fr;
      grid-column-gap: 13px;
      grid-row-gap: 30px;
      grid-template-areas: "Area Area-2 Area-3 Area-4 Area-5";
      -ms-grid-columns: 1fr 13px 1fr 13px 1fr 13px 1fr 13px 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      -ms-grid-rows: 1fr;
      grid-template-rows: 1fr;
      img {
        object-fit: contain;
        padding: 5px;
        max-width: 130px;
      }
    }
  }

  // @media screen and (min-width: 1920px) {
  //   .companies__content {
  //     width: 82vw;
  //     // padding-left: 18vw;
  //   }
  // }
  // @media screen and (min-width: 1440px) {
  //   .companies__content {
  //     width: 85vw;
  //     // padding-left: 13vw;
  //   }
  // }
}

@media screen and (max-width: 500px) {
  .companies {
    .companies__content {
      margin: 14px 0px 14px 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
