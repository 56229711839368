.about-section {
  display: flex;
  overflow: hidden;
  -webkit-box-pack: justify;
  flex-direction: column;

  .title {
    padding-top: 10px;
    padding-bottom: 10px;

    h2 {
      animation: loadleft 2s linear;
      font-size: 3.7rem;
    }
  }

  .about-content {
    p {
      font-size: 18px;
      text-align: justify;
    }
  }
  .skills {
    display: flex;
    position: relative;
    margin-top: 20px;
    .skill-box {
      flex: 1;
      margin-top: 20px;
      position: relative;
      .title {
        position: relative;
        font-size: 30px;
        line-height: 10px;
        padding: 10px 5px 10px 0px;
        text-align: center;
        color: var(--theme-color-primary);
      }
      .number {
        animation: loadbottom 2s linear;
        position: absolute;
        left: 5%;
        top: -10px;
        color: #57575794;
        font-size: 50px;
        font-weight: bold;
      }
      .detail {
        position: relative;
        margin-top: 0px;
        padding-top: 35px;
        padding-bottom: 0px;
        font-size: 18px;
        line-height: 28px;
        font-weight: 300;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .about-section {
    margin-top: 0px;
    margin-bottom: 0px;
    .skills {
      .skill-box {
        .title {
          font-size: 16px;
          padding: 0;
        }
        .number {
          font-size: 18px;
          top: 4px;
          left: 2%;
        }
        .detail {
          font-size: 14px;
          padding-top: 20px;
        }
      }
    }
  }
}
