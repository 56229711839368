.experience-section {
  display: flex;
  -webkit-box-pack: justify;
  flex-direction: column;
  overflow: hidden;
  margin-top: 0px;
  .title {
    padding-top: 10px;
    padding-bottom: 10px;
    animation: loadleft 2s linear;
    h2 {
      font-size: 3.7rem;
    }
  }
}

.container__experience {
  .experience__cards {
    .experience__card {
      margin: 1.5rem 0 1.5rem;
      .title__card {
        display: flex;
        align-items: center;
        h2 {
          margin-right: 10px;
        }
        h5 {
          color: var(--theme-color-primary);
        }
      }
      small {
        color: var(--color-light);
      }
      p {
        white-space: break-spaces;
        text-align: justify;
        font-size: 18px;
        overflow-wrap: break-word;
      }

      &::after {
        content: "°";
        margin: auto;
        margin-top: 30px;
        display: block;
        height: 0.2em;
        min-width: 20vw;
        width: 100%;
        text-align: center;
        //background-color: var(--color-primary);
        background: linear-gradient(
          to right,
          var(--theme-color-primary),
          rgb(0, 0, 0)
        );
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .container__experience {
    .experience__cards {
      .experience__card {
        .title__card {
          h2 {
            font-size: 16px;
          }
          h5 {
            font-size: 12px;
          }
        }
        p {
          font-size: 14px;
        }
      }
    }
  }
}
