.projects-section {
  display: flex;
  -webkit-box-pack: justify;
  overflow: hidden;
  justify-content: space-around;
  margin-bottom: 0px;
  padding-bottom: 50px;
  .title {
    padding-top: 10px;
    animation: loadleft 2s linear;
    h2 {
      font-size: 2rem;
    }
  }

  .box {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    margin: 26px 0px 10px 0px;
    width: 400px;
    height: 300px;
    overflow: hidden;
    position: relative;
    background: black;
    cursor: pointer;
    border: 1px solid #fff;
    box-shadow: rgba(246, 245, 244, 0.1) 0px 56px 64px;
    transition: transform 0.1s ease;
    transform-style: preserve-3d;
    will-change: transform;
    &:hover {
      transform: translateZ(12px);
    }

    h4 {
      margin-right: auto;
      margin-left: auto;
      text-align: center;
    }

    .box__detail {
      flex: 1 1 auto;
      background: black;

      display: flex;
      align-items: center;

      h4 {
        color: var(--theme-color-primary-bolt);
        font-size: 20px;
        font-weight: bold;

        // &.active {
        //   animation: animationopacity linear 1s;
        //   opacity: 1;
        //   -moz-transform: translateY(-50%);
        //   -webkit-transform: translateY(-50%);
        //   transform: translateY(-50%);
        // }
      }
    }
    img {
      aspect-ratio: 4/2.3;
    }
  }
}

@keyframes animationopacity {
  from {
    opacity: 0;
    -moz-transform: translateY(50%);
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
  }
  to {
    opacity: 1;
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

@media screen and (max-width: 900px) {
  .projects-section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    width: 100%;
    .title {
      width: 100%;
      animation: none;
    }
    .container__projects {
      width: 100%;
    }
    .box {
      width: 100%;
      margin-top: 0px;
      box-shadow: none;
      .box__detail {
        width: 100%;
        img {
          padding-left: 0px;
          aspect-ratio: 6/5;
        }
      }
    }
  }
}
@media screen and (max-width: 400px) {
  .projects-section {
    margin-top: 20px;
    // .box {
    //   img {
    //     padding-left: 0px;
    //     aspect-ratio: 4/3.4;
    //   }
    // }
  }
}
