.nav {
  position: fixed;
  top: 0px;
  z-index: 200;
  overflow: hidden;
  width: 100%;
  height: 100px;
  background-image: linear-gradient(
    180deg,
    #000,
    rgba(0, 0, 0, 0.8) 98%,
    transparent
  );
  .nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    .logo {
      font-size: 28px;
    }
    .logo-mb {
      font-size: 24px;
    }
    .nav-menu {
      font-size: 18px;
      display: flex;
      gap: 30px;
      cursor: pointer;

      .active {
        color: var(--theme-color-primary);
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .nav {
    .nav-container {
      .nav-menu {
        font-size: 14px;
        margin-right: 50px;
      }
    }
  }
}
