footer {
  //background: var(--theme-color-primary);
  padding: 3rem 0;
  text-align: center;
  font-size: 0.9rem;
  margin-top: 7rem;

  a {
    color: var(--theme-color-title);
  }

  .footer__logo {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
    display: inline-block;
  }

  .footer__socials {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 4rem;

    a {
      background: var(--color-bg);
      color: var(--color-white);
      padding: 0.8rem;
      border-radius: 0.7rem;
      display: flex;
      border: 1px solid transparent;

      &:hover {
        background: transparent;
        color: var(--color-bg);
        border-color: var(--color-bg);
      }
    }
  }

  .permalinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 3rem;
  }

  .footer__copyright {
    margin-bottom: 4rem;
    font-weight: bold;
    color: var(--theme-color-title);
  }
}
