.contact-section {
  display: flex;
  overflow: hidden;
  height: 400px;
  -webkit-box-pack: justify;
  flex-direction: column;

  .title {
    padding-top: 10px;
    padding-bottom: 10px;
    h2 {
      font-size: 3.7rem;
    }
  }
  .container-information {
    color: var(--theme-color-primary);

    .info {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2%;
      font-size: 18px;
      p {
        text-align: justify;
        color: var(--theme-color-text);
      }
      span {
        color: var(--theme-color-title);

        svg {
          margin-bottom: -4px;
          margin-right: 5px;
          margin-left: 5px;
        }
      }
    }
  }
}
