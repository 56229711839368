.container__sites {
  margin-top: 30px;
  .title {
    padding-top: 10px;
    animation: loadleft 2s linear;
    h2 {
      font-size: 2rem;
    }
  }
  .container__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;

    .box__site {
      border: 1px solid #fff;
      padding: 5px;

      &:hover {
        background-color: #fff;
        a {
          color: black;
        }
      }
    }
  }
  // .container__list > div {
  //   width: 50%;
  //   padding: 10px;
  // }
  // div:nth-of-type(even) {
  //   text-align: left;
  // }
  // div:nth-of-type(odd) {
  //   text-align: right;
  // }
}

@media screen and (max-width: 500px) {
  .container__sites {
    .container__list {
      .box__site {
        a {
          font-size: 14px;
        }
      }
    }
  }
}
