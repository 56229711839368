.modal__backdrop {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background: rgb(0, 0, 0, 0.6);
  z-index: 99999;

  .modal__content__wraper {
    position: fixed;
    width: 700px;
    height: 600px;
    background: white;
    margin: auto;
    padding: 30px;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: scroll;

    .modal__content {
      h2 {
        color: black;
      }
      a {
        color: rgb(18, 59, 209);
      }
    }
  }
}
