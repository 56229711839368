.home-page {
  width: 100%;
}
// .App {
//   text-align: center;
// }

// .App-logo-php {
//   width: 200px;
//   height: 100px;
//   pointer-events: none;
//   position: absolute;
//   left: calc(100vh - 530px);
//   transform: rotate(-20deg);
//   top: 650px;
//   z-index: -5;
// }
// .App-logo-react {
//   width: 100px;
//   height: 100px;
//   pointer-events: none;
//   position: absolute;
//   right: calc(100vh - 350px);
//   top: 50px;
//   z-index: -5;
// }
// .App-logo-py {
//   width: 80px;
//   height: 80px;
//   pointer-events: none;
//   position: absolute;
//   left: calc(100vh - 420px);
//   top: 50px;
//   z-index: -5;
// }
// .App-logo-node {
//   width: 100px;
//   height: 100px;
//   pointer-events: none;
//   position: absolute;
//   left: calc(100vh - 400px);
//   top: 500px;
//   z-index: -5;
// }
// .App-logo-sass {
//   width: 100px;
//   height: 100px;
//   pointer-events: none;
//   position: absolute;
//   right: calc(100vh - 320px);
//   top: 400px;
//   z-index: -5;
// }

// @media (prefers-reduced-motion: no-preference) {
//   .App-logo-py {
//     animation: App-logo-spin 40s infinite linear;
//     width: 100px;
//     height: 100px;
//   }
//   .App-logo-react {
//     animation: App-logo-spin-2 5s infinite linear;
//     width: 100px;
//     height: 100px;
//   }
//   .App-logo-node {
//     animation: App-logo-spin-3 2s infinite linear;
//     width: 100px;
//     height: 100px;
//   }
// }
// /* ---- media query --- */
// @media screen and (max-width: 600px) {
//   .App-logo-node,
//   .App-logo-php,
//   .App-logo-py,
//   .App-logo-react,
//   .App-logo-sass {
//     display: none;
//   }
// }

// .App-header {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }

// .App-link {
//   color: #61dafb;
// }

// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(-360deg);
//   }
// }

// @keyframes App-logo-spin-2 {
//   0% {
//     top: 0px;
//     transform: rotate(0 deg);
//   }
//   50% {
//     top: 50px;
//     transform: rotate(180deg);
//   }
//   100% {
//     top: 0px;
//     transform: rotate(360deg);
//   }
// }

// @keyframes App-logo-spin-3 {
//   0% {
//     top: 300px;
//     right: 0px;
//   }
//   25% {
//     top: 325px;
//     right: 25px;
//   }
//   75% {
//     top: 350px;
//     right: 50px;
//   }
//   50% {
//     top: 325px;
//     right: 25px;
//   }
//   100% {
//     top: 300px;
//     right: 0px;
//   }
// }
