.link {
  margin-top: 20px;
  animation: loadelement 2s linear;
  .btn {
    background-color: transparent;
    border-bottom: 1px solid var(--theme-color-primary);
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 5px;
    letter-spacing: 2px;
    color: var(--theme-color-title);
  }

  :hover {
    color: var(--theme-color-primary);
  }
}

@keyframes loadelement {
  from {
    -moz-transform: translateY(100%);
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  to {
    -moz-transform: translateY(0%);
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}
