.main-section {
  display: flex;
  overflow: hidden;
  height: 700px;
  -webkit-box-pack: justify;
  justify-content: center;
  align-items: center;
  .side-content {
    h2,
    h3 {
      animation: loadtop linear 2s;
    }
    p {
      padding-right: 90px;
      color: var(--theme-color-text);
      text-align: justify;
      padding-top: 10px;
      padding-bottom: 10px;
      animation: loadleft linear 2s;
    }
  }
  .img-content {
    .me {
      background: linear-gradient(90deg, var(--theme-color-primary), #000000);
      width: 22rem;
      height: 25rem;
      margin-top: 4rem;
      border-radius: 12rem 12rem 0 0;
      overflow: hidden;
      padding-top: 60px;
      display: flex;
      animation: loadright linear 2s;
      img {
        filter: grayscale(75%);
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .main-section {
    display: flex;
    flex-direction: column;
    height: 480px;
    padding-bottom: 20px;
    justify-content: end;
    .side-content {
      text-align: center;
      p {
        color: var(--theme-color-text);
        text-align: center;
        padding-right: 0;
      }
    }
  }
}
